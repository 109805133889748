/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import { getPlayersGroups, getPlayersGroupsByGroup, getPlayersGroupsByFacility } from '@api'

export default function usePlayersGroupList() {
  const dataListTable = ref([])
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  // { text: t('facilities.facilities').toUpperCase(), value: 'facilities_ids', show: true },
  const tableColumns = computed(() => [
    { text: t('sports.name').toUpperCase(), value: 'name', show: true },
    { text: t('highlights.created_date').toUpperCase(), value: 'creation_date', show: true },
    { text: t('players_group.visibility').toUpperCase(), value: 'visibility_str', show: true },
    { text: t('notify_players.members').toUpperCase(), value: 'members_qty', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const totalDataListTable = ref(0)
  const searchQuery = ref('')
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const dataTotalLocal = ref([])

  // fetch data
  const fetchPlayersGroups = async () => {
    loading.value = true
    let playersGroups = null
    if (configFacility.value) {
      playersGroups = await getPlayersGroupsByFacility(configFacility.value)
    } else if (userData.value && userData.value.role !== 'A') {
      playersGroups = await getPlayersGroupsByGroup(userData.value.group_id)
    } else {
      playersGroups = await getPlayersGroups()
    }

    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption

    const filteredData = playersGroups.data.filter(
      e =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        e.name.toLowerCase().includes(searchQuery.value) ||
        (e.captain && e.captain.fullname.toLowerCase().includes(searchQuery.value)),
    )

    dataListTable.value = filteredData
    totalDataListTable.value = filteredData.length
    dataTotalLocal.value = null
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchPlayersGroups()
    }, time)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  // watch([options], async () => {
  //   if (flag.value) {
  //     loading.value = true
  //     await fetchPlayersGroups()
  //   }
  // })

  watch([configFacility, configOrganization], async () => {
    searchQuery.value = ''
    await getDataBySearch(200)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending' || status === 'P') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'
    if (status === 'B') return 'info'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    dataListTable,
    tableColumns,
    computedTableColumns,
    totalDataListTable,
    searchQuery,
    loading,
    options,
    filters,
    dataTotalLocal,

    configOrganization,
    configFacility,

    fetchPlayersGroups,
    resolveStatusVariant,
    resolveStatusText,
    hasPermission,
    setFilters,
    setOptions,
  }
}
