<template>
  <div id="players-group-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('Players Group') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer v-if="hasPermission(50)" />
        <v-col
          v-if="hasPermission(50)"
          cols="12"
          md="2"
          sm="2"
        >
          <v-btn
            block
            color="secondary"
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(50)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="dataListTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.logo ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.logo"
                :src="item.logo"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'views-players-group-form', params: { id: item.id, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.captain.fullname`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.logo ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.captain.avatar"
                :src="item.captain.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.captain.fullname) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'views-players-group-form', params: { id: item.id, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.captain.fullname }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.captain_phone_number`]="{item}">
          {{ formatNumber(item.captain_phone_number) }}
        </template>

        <template #[`item.visibility_str`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.visibility)"
            :class="`${resolveStatusVariant(item.visibility)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.visibility_str }}
          </v-chip>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(49)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Details</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(51)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(52)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import usePhone from '@core/utils/usePhone'

import { deletePlayersGroup } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import usePlayersGroupList from './usePlayersGroupList'

export default {
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()
    const { formatNumber } = usePhone()

    const {
      dataListTable,
      computedTableColumns,
      totalDataListTable,
      searchQuery,
      loading,
      options,
      filters,
      dataTotalLocal,

      fetchPlayersGroups,
      resolveStatusVariant,
      resolveStatusText,
      hasPermission,
      setFilters,
      setOptions,
    } = usePlayersGroupList()

    const statusOptions = [
      { title: 'Active', value: 'A' },
      { title: 'Inactive', value: 'I' },
    ]

    const onCreate = () => {
      router.push({
        name: 'views-players-group-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
          backTo: 'views-players-group-list',
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-players-group-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
          backTo: 'views-players-group-list',
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-players-group-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
          backTo: 'views-players-group-list',
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deletePlayersGroup(id)
          if (response.ok) {
            success(response.message)
            await fetchPlayersGroups()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      if (pageParams) setOptions(pageParams)
      if (filterParams) setFilters(filterParams)

      await fetchPlayersGroups()
    })

    return {
      dataListTable,
      computedTableColumns,
      totalDataListTable,
      searchQuery,
      statusOptions,
      loading,
      options,
      filters,
      dataTotalLocal,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      resolveStatusVariant,
      resolveStatusText,
      fetchPlayersGroups,
      hasPermission,
      setFilters,
      setOptions,
      formatNumber,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
